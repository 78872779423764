.setwidth {
  max-height: 31vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.setwidth::-webkit-scrollbar {
  width: 3px; /* Adjust the width of the scrollbar */
}
.iota{
  overflow-y: scroll;
  max-height: 200px;
}

/* Track */
.setwidth::-webkit-scrollbar-track {
  background: transparent; /* Set the background color of the scrollbar track */
}

/* Handle */
.setwidth::-webkit-scrollbar-thumb {
  background: white; /* Set the color of the scrollbar handle */
}

/* Handle on hover */
.setwidth::-webkit-scrollbar-thumb:hover {
  background: white; /* Set the color of the scrollbar handle on hover */
}
