/* .login-form {
    padding: 5%;
    width: 50%;
}

.textfield {
    color:white;
    border-color: rgb(107, 75, 75);   
}

.textfield__label {
    color: white !important;
}

fieldset {
    border-color: white !important;
} */

/* .MuiSvgIcon-root */
/* .MuiFormLabel-root.Mui-focused {
    color: red !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
  } */


  .login_main_image{
    /* max-height: 45vw;
     */
     width: 45vw !important;
     max-width: 500px;
  }

  .landing_page_logo{

    display: flex;
    justify-content: center;
  }

  .landing_privacy_link{

  }
  
  @media (max-height: 600px) {
      .login_main_image {
        max-width: 33vw !important;
        margin-right: 2rem;
        /* margin-bottom: 3rem; */
        /* padding-bottom: 2.5rem; */
      }

      .login_main_image_container{
        padding-top: 2.5rem;
      }

      .landing_page_logo{
        margin-bottom: 2px;
        margin-top: 4px;
      }

      .landing_privacy_link{
        padding-top: 8px;
        margin-top: 8px;
      }
    }