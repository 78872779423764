#text-area-comment {
  color: red;
  background-color: pink;
  background-color: var(--color-text-blackgray);
}
.changeCloseMainDiv {
  padding-top: 0.5rem;
  background-color: #dbdbdb;
  /* min-height: 92vh; */
  padding-bottom: 5rem;
}
.closeForm {
  min-height: 450px;
}

.closeSelect {
  margin-top: 2rem;
  margin-left: 4rem;
}
.closeSelect select {
  width: 15rem;
  border: 1px solid #dbdbdb;
}
.closeForm textarea {
  border: 1px solid #dbdbdb;
  width: 80% !important;
  margin-left: 4rem;
}
.check1,
.check2 {
  margin-left: 4rem;
}
.closeForm .field4 {
  margin-left: 4rem !important;
}

@media screen and (max-width: 768px) {
  .check1,
  .check2 {
    margin-left: 2rem;
  }
  .closeForm textarea {
    margin-left: 2rem;
  }
  .closeForm .field4 {
    margin-left: 2rem !important;
  }
  .closeSelect {
    margin-left: 2rem;
  }
}
