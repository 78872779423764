.active {
  background: blue;
  color: #fff;
  padding: 10px 10px;
  border: none;
  outline: none;
}
.typeBtn {
  border: none;
  background: black;
  outline: none;
  color: #fff;
  padding: 10px 10px;
}

#root > div > form > div > div:nth-child(4) > div > div > div > button > svg {
  color: white;
}

.textSize2xl {
  font-size: 1.5rem !important;
}
