p a {
  color: rgb(56, 186, 246);
  text-decoration: none;
}

:root {
  --bgColor: #f5f6f7;
}

/* h2 {
  font-size: small !important;
} */
.mycontents {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.skilltype {
  /* margin-top: 20px !important;
    margin-left: 39px;
    width: 86% !important; */

}

.contentbtn {
  background-color: teal !important;
  margin-top: 35px;

}

.cbc {
  background-color: teal !important;
}

.contentbtn:hover {
  transform: scale(1.1);
  transition: ease-in-out 0.3s;
}

.Tt {
  font-size: 1.6rem !important;

}

.container {
  padding: 15px;
  background: var(--bgColor);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  width: 1200px;
  margin: 0 auto;
}

.mt-15 {
  margin-top: 15px;
}

.card {
  background: #fff;
  padding: 15px;
}

.card__h3 {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
}

.group {
  margin-bottom: 13px;
}

.image__label {
  width: 80px;
}

.group__control {
  width: 80%;
  padding: 15px;
  border: none;
  background: silver;
  outline: none;
  border-bottom: 2px solid transparent;
  transition: all 0.5s;
}

.group__control:focus {
  border-bottom-color: #000;
}

#image {
  display: none;
  width: 80%;
}

.image__label {
  display: block;
  width: 100%;
  padding: 15px;
  background: #000;
  color: #fff;
  cursor: pointer;
}

.quill {
  position: relative !important;
  /* width: 100% !important; */
}

.btn {
  text-decoration: none;
  outline: none;
  /* color: #fff; */
  height: 4rem;
  padding: 1.2rem 1rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.4rem;
  border: none;
  cursor: pointer;
}

.btn-default {
  background: #000;
}

.btn-block {
  width: 100%;
  height: 5rem !important;
}

.p-15 {
  padding: 15px;
}

.ml-minus-15 {
  margin-left: -15px;
}

mr-minus-15 {
  margin-right: -15px;
}

.imagePreview {
  width: 100%;
  height: auto;
}

.Title input {
  /* color: #000 !important; */
}

.pppp {
  max-width: 100%;
  word-wrap: break-word;
}

.imagePreview img {
  width: 100%;
  height: auto;
}

.length {
  font-size: 15px;
}

.dialogs {
  border: none !important;
}

@media (max-height: 560px) {
  .iput {
    margin-bottom: 20px !important;
    text-overflow: none !important;
  }

  .okk {
    overflow-y: auto;
    max-height: 70vh !important;
  }
}

@media (max-width: 1000px) {
  .skillof {
    width: 45% !important;
  }

  .statesss {
    width: 3rem !important;
  }
}

.ql-editor {
  height: 690px;
  overflow-y: auto;
  /* overflow: scroll !important; */

  width: 100%;
  font-size: 1rem;
}

.imagePreview img {
  width: 100%;
  height: auto;
}

.length {
  font-size: 14px;
}

.dialogs {
  border: none !important;
}

@media (max-height: 560px) {
  .iput {
    margin-bottom: 18px !important;
    text-overflow: none !important;
  }

  .okk {
    overflow-y: auto;
    max-height: 70vh !important;
  }
}

@media (max-width: 1000px) {
  .skillof {
    width: 45% !important;
  }

  .statesss {
    width: 3rem !important;
  }
}

.ql-editor {
  height: 690px;
  overflow-y: auto;
  /* overflow: scroll !important; */

  width: 100%;
  font-size: 1rem;
}

.firsts {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding: 20px; */
  align-items: center;
}

.statesss {
  width: 1rem;
}

.ql-editor::-webkit-scrollbar {
  width: 3px;
  /* Adjust the width of the scrollbar */
  max-height: 5%;
  height: 5%;
}

/* Track */
.ql-editor::-webkit-scrollbar-track {
  background: transparent;
  /* Set the background color of the scrollbar track */
}

/* Handle */
.ql-editor::-webkit-scrollbar-thumb {
  background: grey;
  /* Set the color of the scrollbar handle */
}

/* Handle on hover */
.ql-editor::-webkit-scrollbar-thumb:hover {
  background: #008080;
  /* Set the color of the scrollbar handle on hover */
}

.ql-toolbar {
  position: absolute;
  z-index: 1;
  background-color: white;
  width: 100%;
  overflow-x: hidden;
}

.ql-editor p a {
  color: blue !important;
}

.ql-editor::-webkit-scrollbar {
  /* display: none; */
}

.ql-tooltip {
  left: 0;
}

.ql-container {

  /* visibility: hidden; */
  overflow-y: auto;
}

.quill>.ql-container>.ql-editor.ql-blank::before {
  color: #637381;
}