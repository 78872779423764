.allskills::-webkit-scrollbar {
  width: 7px; /* Adjust the width of the scrollbar */
}
/* .allskills{
  visibility: hidden
} */

/* Track */
.allskills{
  /* overflow-y: scroll; */
  /* height: 20vh; */
}
.allskills::-webkit-scrollbar-track {
  background: transparent; /* Set the background color of the scrollbar track */
}

/* Handle */
.allskills::-webkit-scrollbar-thumb {
  background: #008080; /* Set the color of the scrollbar handle */
  border-radius: 50px;
}

/* Handle on hover */
.allskills::-webkit-scrollbar-thumb:hover {
  background: #006666; /* Set the color of the scrollbar handle on hover */
}
