/* Button Style */
.Polished {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    width: 10rem;
    transition: background-color 0.3s ease;
    color: #ffffff; /* Text color */
    background-color:#008080; /* Green color */
    border: 2px solid #008080; /* Green border */
  }
  
  /* Hover Effect */
  .Polished:hover {
    background-color: #008079; /* Darker green on hover */
  }
  
  /* Optional: Add more styles for focus and active states if needed */
  .Polished:focus {
    outline: none; /* Remove focus outline */
    /* Add additional focus styles if desired */
  }
  
  .Polished:active {
    background-color: #016565; /* Darker green when button is clicked */
    /* Add additional styles for active state if desired */
  }
  
/* Button Style */
.Novice {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    width: 10rem;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    color: #ffffff; /* Text color */
    background-color: #e6b800; /* Yellow color */
    border: 2px solid  #e6b800 /* Yellow border */
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0; /* Optional margin for spacing */
    margin-bottom: 0;
  }
  
  /* Hover Effect */
  .Novice:hover {
    background-color: #b08d00; /* Darker yellow on hover */
  }
  
  /* Optional: Add more styles for focus and active states if needed */
  .Novice:focus {
    outline: none; /* Remove focus outline */
    /* Add additional focus styles if desired */
  }
  
  .Novice:active {
    background-color: #d6ad00; /* Darker yellow when button is clicked */
    /* Add additional styles for active state if desired */
  }
  