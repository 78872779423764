body::-webkit-scrollbar {
  z-index: 1;
  width: 0px;
  border: 2px;
  border-radius: 5%;
  color: teal;
}
.tune{
  width: 20px;
}

.post-scroll {
  padding-bottom: 0px;
  min-width: 380px;
}
.posts {
  /* max-height: 100vh; */
  height: fit-content;
  overflow-y: scroll;
  overflow-x: hidden;
}

body::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     */
  z-index: 1;
  border-radius: 15px;
  @apply bg-neutral;
}
/* #home-content{border: 3px solid orange;} */

body::-webkit-scrollbar-thumb {
  z-index: 1;
  background: teal;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 10px;
}
.posts::-webkit-scrollbar {
  display: none !important;
}
body::-webkit-scrollbar-thumb:active {
  background: rgb(0, 101, 101);
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 10px;
}
.posts {
  scrollbar-width: none;
  scrollbar-color: teal transparent;
}
.posts::-webkit-scrollbar-thumb:active {
  display: none !important;
}


.post-scroll::-webkit-scrollbar {
  display: hidden;
}
.spacer {
  width: 0px !important;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: teal transparent;
}

.scrollbar::-webkit-scrollbar {
  z-index: 1;
  width: 8px; /* Adjust the width of the scrollbar */
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Set the background color of the scrollbar track */
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #008080; /* Set the color of the scrollbar handle */
  border-radius: 50px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #006666; /* Set the color of the scrollbar handle on hover */
}

.scrollbar-leftsec {
  scrollbar-width: none;
  scrollbar-color: teal transparent;
}

.scrollbar-leftsec::-webkit-scrollbar {
  z-index: 1;
  width: 0px; /* Adjust the width of the scrollbar */
  /* visibility: hidden; */
}
/* .scrollbar{
  visibility: hidden
} */

/* Track */
.scrollbar-leftsec::-webkit-scrollbar-track {
  background: transparent; /* Set the background color of the scrollbar track */
}

/* Handle */
.scrollbar-leftsec::-webkit-scrollbar-thumb {
  background: #008080; /* Set the color of the scrollbar handle */
  border-radius: 50px;
}

/* Handle on hover */
.scrollbar-leftsec::-webkit-scrollbar-thumb:hover {
  background: #006666; /* Set the color of the scrollbar handle on hover */
}

.RightSideHome {
  scrollbar-width: none;
  scrollbar-color: teal transparent;
}
@media  (max-width : 560px) {
  .mmm{
    margin: 0 auto !important;
  }
  
}

.heightOfLeftAndRightSec{
  height: calc(100vh - 65px);
}

/* Define the spinning animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Apply the animation to an element with the "spinner" class */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid teal; /* You can adjust the color */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite; /* Apply the spinning animation */
}
