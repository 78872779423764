.AllFriends {
  position: sticky;
  position: fixed;
  z-index: 300;
  border-radius: 10px;
  top: 0px;
  right: 0px;
  display: block;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--color-text-primary);
}

div {
  /* height: fit-content; */
}

.AllFriendsContainer {
  /* width: 100vw; */
  /* max-width: 700px; */
  background-color: var(--color-bg-card);
  /* height: 50vh; */
  border-radius: 12px;
  /* overflow-y: scroll;
  overflow-x: hidden; */
}

.AllFriendsContainer .top>button {
  width: max-content;
  margin: 5px;
  color: red;
  /* border: 2px solid red; */
  background-color: transparent;
  padding: 3px;
}

.AllFriendsContainer .top {
  /* width: 100vw; */
  max-width: 600px;
  /* box-shadow: 2px 0px 6px grey; */
  @apply border-b border-viewMore;
  position: sticky;
  top: 0px;
  z-index: 60;
  background-color: var(--color-bg-card);
  display: flex;
  justify-content: space-between;
  /* font-size: 1.5rem; */
  /* padding-top: 5px; */
  /* padding: 8px 5px; */
}

/* .AllFriendsContainer .top > p {
  font-size: 1.5rem;
} */
.allfriends {
  border-radius: 0 0 12px 12px;
  /* margin-top: 5px; */
  height: 40vh;
  overflow: scroll;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .AllFriendsContainer {
    width: 80% !important;
  }
}

.allfriends::-webkit-scrollbar {
  width: 7px;
  /* Adjust the width of the scrollbar */
}

/* Track */
.allfriends::-webkit-scrollbar-track {
  background: transparent;
  /* Set the background color of the scrollbar track */
}

/* Handle */
.allfriends::-webkit-scrollbar-thumb {
  background: #008080;
  /* Set the color of the scrollbar handle */
  border-radius: 50px;
}

/* Handle on hover */
.allfriends::-webkit-scrollbar-thumb:hover {
  background: #006666;
  /* Set the color of the scrollbar handle on hover */
}