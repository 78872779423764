/* .passwordForm{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color:#d3d3d3;
    padding:2rem;border-radius:10px;
} */

.changePassMainDiv {
  padding-top: 0.5rem;
  background-color: #dbdbdb;
  /* min-height: 92vh; */
  padding-bottom: 5rem;
}
.passwordForm {
  min-height: 400px;
}

.passwordForm .field1,
.passwordForm .field2,
.passwordForm .field3 {
  margin-top: 2rem;
  margin-left: 4rem;
}

.passDivider {
  position: relative;
  top: 2rem;
  left: 4rem !important;
}
.passwordForm .field4 {
  margin-top: 4rem;
  margin-left: 4rem !important;
}

@media screen and (max-width: 768px) {
  .passwordForm .field1,
  .passwordForm .field2,
  .passwordForm .field3,
  .passwordForm .field4 {
    margin-left: 2rem !important;
    /* width: 100%; */
  }
  .passDivider {
    left: 2rem !important;
  }
}
