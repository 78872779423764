/* .landingPage { */
/* height: 100vh;
    display: flex;
    align-items: center;
    margin: 0;
    background-color: rgba(18,18,18); */
/* background-color: rgba(0, 0, 0, 0.5); */
/* background: linear-gradient(
        90deg,
        #424242 32.2%,
        rgba(255, 255, 255, 1) 100%
    ); */
/* } */
/* *{
    overflow-x: hidden;
    overflow-y: hidden;
} */



.gsi_material_button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    vertical-align: middle;
    white-space: nowrap;
    width: 40px;
    max-width: 400px;
    min-width: min-content;
  }
  
  .gsi_material_button .gsi_material_button_icon {
    height: 2rem;
    margin-right: 12px;
    min-width: 20px;
    width: 32px;
    margin: 3px;
    padding: 6px;
  }
  
  .gsi_material_button .gsi_material_button_content_wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .gsi_material_button .gsi_material_button_contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  
  .gsi_material_button .gsi_material_button_state {
    -webkit-transition: opacity .218s;
    transition: opacity .218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .gsi_material_button:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }
  
  .gsi_material_button:disabled .gsi_material_button_contents {
    opacity: 38%;
  }
  
  .gsi_material_button:disabled .gsi_material_button_icon {
    opacity: 38%;
  }
  
  .gsi_material_button:not(:disabled):active .gsi_material_button_state, 
  .gsi_material_button:not(:disabled):focus .gsi_material_button_state {
    background-color: #303030;
    opacity: 12%;
  }
  
  .gsi_material_button:not(:disabled):hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  }
  
  .gsi_material_button:not(:disabled):hover .gsi_material_button_state {
    background-color: #303030;
    opacity: 8%;
  }
  

  .orLine {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    margin-bottom: 8px;
  }

  
  
  .line {
    flex-grow: 1;
    height: 1px;
    opacity: 50%;
    /* background-color: #000000; */
  }

  .darkLine {
    flex-grow: 1;
    height: 1px;
    opacity: 50%;
    /* background-color: #ffffff; */
  }
  
  .orText {
    margin: 0 10px;
  }
  
  .orLineMobile {   
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
  }

  .lineMobile {
    flex-grow: 1;
    height: 1px;
    opacity: 50%;
    background-color: #000;
  }
