.edit-button:hover {
  @apply bg-teal-500;
  color: white;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.edit-button {
  background-color: teal;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px;
  cursor: pointer;
  @apply rounded-lg transition duration-200;
  float: right;
}
