.overflow-scroll::-webkit-scrollbar { 
    display: none; 
}

.overflow-y-scroll::-webkit-scrollbar { 
    background-color: transparent; 
    width: 4px
}

.overflow-y-scroll::-webkit-scrollbar-thumb { 
    background-color: #A5A5A5; 
}


/* .overflow-y-scroll::-webkit-scrollbar-track {
    color: red; 
  } */