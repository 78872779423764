@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

/* @font-face {
  font-family: 'Gilroy';
  src: url('../public/assets/fonts/Gilroy-Light.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-ExtraBold.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-Black.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-BlackItalic.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-Bold.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-BoldItalic.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-Heavy.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-HeavyItalic.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-Light.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-LightItalic.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-Medium.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-MediumItalic.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-Regular.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-RegularItalic.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-SemiBold.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-SemiBoldItalic.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-Thin.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-ThinItalic.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-UltraLight.ttf') format('ttf'),
    url('../public/assets/fonts/Gilroy-UltraLightItalic.ttf') format('ttf');

} */
@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('../public/assets/fonts/Gilroy-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('../public/assets/fonts/Gilroy-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Black';
  src: url('../public/assets/fonts/Gilroy-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-BlackItalic';
  src: url('../public/assets/fonts/Gilroy-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../public/assets/fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-BoldItalic';
  src: url('../public/assets/fonts/Gilroy-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Heavy';
  src: url('../public/assets/fonts/Gilroy-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-HeavyItalic';
  src: url('../public/assets/fonts/Gilroy-HeavyItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-LightItalic';
  src: url('../public/assets/fonts/Gilroy-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../public/assets/fonts/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-MediumItalic';
  src: url('../public/assets/fonts/Gilroy-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../public/assets/fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-RegularItalic';
  src: url('../public/assets/fonts/Gilroy-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('../public/assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-SemiBoldItalic';
  src: url('../public/assets/fonts/Gilroy-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Thin';
  src: url('../public/assets/fonts/Gilroy-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-ThinItalic';
  src: url('../public/assets/fonts/Gilroy-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-UltraLight';
  src: url('../public/assets/fonts/Gilroy-UltraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-UltraLightItalic';
  src: url('../public/assets/fonts/Gilroy-UltraLightItalic.ttf') format('truetype');
}

.dark {
  --color-bg-primary: rgba(22, 28, 36, 1);
  --color-bg-secondary: #141414;
  /* --color-text-primary: rgb(179, 179, 179); */
  --color-text-primary: #C9D3DE;

  /* --color-text-secondary: rgba(99, 115, 129, 1); */
  --color-text-secondary: #D1E0F2;

  
  --color-text-accent: #81e6d9;
  /* --color-bg-card: #212b36; */
  --color-bg-card: #0D0D0D;

  --color-text-current: #212b36;
  --color-text-card: #ffffff;
  --color-bg-page: #161c24;
  --color-bg-message: #212b36;
  --color-text-message: #707c97;
  --color-text-grayish: #637381;
  --color-text-blackgray: #637381;
  --color-bg-accountinfo: #212b36;
  --color-text-appreciatecomment: ;
  --color-bg-appreciatecomment: rgba(0, 128, 128, 0.08);
  --color-text-appreciatecomment: rgba(0, 128, 128, 1);
  --color-bg-improvecomment: rgba(255, 193, 7, 0.08);
  --color-text-improvecomment: rgba(139, 106, 8, 1);
  --color-text-viewmore: rgba(99, 115, 129, 1);
  --color-bg-viewmore: rgba(33, 43, 54, 1);
  --color-border-viewmore: rgba(145, 158, 171, 0.32);
  --color-border-comment: rgba(145, 158, 171, 0.32);
  --color-bg-post: rgba(22, 28, 36, 1);
  --color-bg-hover: rgba(33, 43, 54, 1);
  --color-fill-svg: rgb(255, 255, 255);
  /* --color-bg-neutral: rgba(22, 28, 36, 1); */
  --color-bg-neutral:#0D0D0D;

  
  /* --color-bg-feedNeutral: rgba(33, 43, 54, 1); */
  --color-bg-feedNeutral:#0D0D0D;

  --color-text-blog: rgba(255, 255, 255, 1);
  --color-bg-darkLight: rgba(22, 28, 36, 1);
  --color-bg-darkWhite: rgb(179, 179, 179);
  --color-text-compWhite: #FFFFFF;
  --color-text-compPrimary: #9C9C9D;
  --color-border-dark:#2B2B2B;
  

  --color-bg-compBgSec: #171718;
  --color-bg-appreciateIcon: #141414;
  --color-bg-homePageBody: #0D0D0D;
  --color-bg-header:#313131;

  --color-bg-compBg: #141414;
  --color-bg-compBgSec: #0D0D0D;
}

.light {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #e8e8e8;
  --color-text-primary: #2d3748;
  --color-text-secondary: #4a5568;
  --color-text-accent: #2b6cb0;
  --color-bg-card: #ffffff;
  --color-text-current: #eeeeee;
  --color-text-card: #000000;
  --color-bg-page: #f5f5f5;
  --color-bg-message: #ebebeb;
  --color-text-message: #637381;
  --color-text-grayish: #767676;
  --color-text-blackgray: black;
  --color-bg-accountinfo: #eeeeee;
  --color-text-appreciatecomment: ;
  --color-bg-appreciatecomment: rgba(0, 128, 128, 0.08);
  --color-text-appreciatecomment: rgba(0, 128, 128, 1);
  --color-bg-improvecomment: rgba(255, 193, 7, 0.08);
  --color-text-improvecomment: rgba(139, 106, 8, 1);
  --color-text-viewmore: rgba(99, 115, 129, 1);
  --color-bg-viewmore: rgba(238, 238, 238, 1);
  --color-border-viewmore: rgba(220, 220, 220, 1);
  --color-border-comment: rgba(219, 219, 219, 1);
  --color-bg-post: rgba(235, 235, 235, 1);
  --color-bg-hover: rgb(243 244 246);
  --color-fill-svg: rgb(0, 0, 0);
  --color-bg-neutral: #e8e8e8;
  --color-bg-feedNeutral: #fff;
  --color-text-blog: rgba(0, 0, 0, 1);
  --color-bg-darkLight: #fff;
  --background-color-bg-light: #fff;
  --color-bg-whiteDark: #2d3748;
  --color-text-compPrimary: #707C97;
  --color-text-compSec: #0D1C2E;
  --color-bg-compBg: #FFFFFF;
  --color-bg-compBgSec: #F6F5FA;

}



@tailwind base;
@tailwind components;
@tailwind utilities;

.mobileBackground {
  background: linear-gradient(180deg, #354064 76.56%, #0f1d4e 100%);
}

::selection {
  background-color: #00808036;
}

@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy-Medium", sans-serif;
}

.shadow-border {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.covo-text {
  font-family: "Roboto", sans-serif;
  color: #707c97;
}

/* ##################### */
.greenBg {
  color: #fff;
  background-color: #008080;
  padding: 0.4rem;
  border-radius: 25px;
  width: 100%;
}

/* ##################### */

.login-background {
  background: linear-gradient(90.81deg,
      rgba(255, 255, 255, 0.12) 0.59%,
      rgba(255, 255, 255, 0) 99.25%,
      #ffffff 99.25%);
}

.skills-background {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.58) 0%,
      rgba(255, 255, 255, 0.35) 100%);
}

.skill-container::-webkit-scrollbar {
  width: 0vw;
  @apply bg-neutral;
}

.mobile-home-notification {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.28) 0%,
      rgba(255, 255, 255, 0.28) 20.83%,
      rgba(255, 255, 255, 0.0924) 100%);
}

.homepageContainer {
  background: linear-gradient(180deg, #354064 76.56%, #0f1d4e 100%);
}

.post-scroll::-webkit-scrollbar {
  width: 0vw;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

@layer utilities {
  .bottom-zero {
    /* Your styles here */
    margin-bottom: 0px !important;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;800;900&display=swap');

body {
  /* background-color: #171616; */
  color: #000000;
  font-family: 'Inter', sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}