
.scrollDiv {
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.scrollDiv::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 20px;

  }
  
.scrollDiv::-webkit-scrollbar-thumb {
    border: 1px solid teal ; 
    border-radius: 20px;
    background-color: teal;
  }
  
.scrollDivmob {
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.scrollDivmobm::-webkit-scrollbar {
    height: 5px;
    width: 0;
    border-radius: 20px;
  }
  
.scrollDivmobm::-webkit-scrollbar-thumb {
    width: 0;
    border: 1px solid teal ; 
    border-radius: 20px;
    background-color: teal;
  }
    
  @media only screen and (max-width:768px) {
    .scrollDiv::-webkit-scrollbar {
      height: 5px;
      width: 3px;
      border-radius: 20px;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;
      display: none;
    }
  }
  @media only screen and (max-width:600px) {
    .scrollDiv::-webkit-scrollbar {
      height: 5px;
      width: 0;
      border-radius: 20px;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;
      display: none;
    }
  }