.photoPosts {
  display: flex;
  width: 100%;
  background-color: rgba(18, 18, 18);
}

.right {
  width: 30%;
}

.center {
  display: inline-block;
  margin-left: 20px;
  width: 45%;
}

.left {
  width: 25%;
}
.closeButton {
  color: white;
  background-color: teal;
  padding: 4px 6px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid teal;
}
