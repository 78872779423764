.container2::-webkit-scrollbar {
  display: none;
}

.container2 {
  scrollbar-width: none;
  scrollbar-color: teal transparent;
}
.button {
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #ffffff;
  text-align: center;
  /* font-size: 28px; */
  padding: 100px;
  /* width: px; */
  transition: all 0.5s;
  cursor: pointer;
  /* margin: 5px; */
  width: 5px;
  height: 5px;
}

.button h2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button h2:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover h2 {
  padding-right: 25px;
}

.button:hover h2:after {
  opacity: 1;
  right: 0;
}

.similarUser::-webkit-scrollbar {
  display: none;
}
