.searching {
  color: red;
  /* display: block; */
}

.not {
  color: black;
  display: none;
}

.unseenNoti {
  display: inline-block;
  width: 15px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  top: -15px;
  left: -12px;
}

.srch {
  max-height: 400px;
  overflow-y: scroll;

}

@media screen and (max-width:770px) {
  .srch {
    max-height: 90vh;
    width: 100%;


  }

  .mobiles {
    width: 100vw;
    left: 0;
    right: 0;
    margin-top: 35px;
    max-height: 75vh;
  }

  .pos {
    position: fixed;
    margin-top: 6px;
    right: 0;
    margin-right: 6px;
  }

  .mobiless {
    width: 80%;
    overflow-x: hidden;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    overflow-y: scroll;
    /* You can adjust the width as needed */
    /* Adjust the max-width as needed */
    /* background-color: #fff; */
    /* Set the background color as needed */
    border-radius: 0.375rem;
    /* You can adjust the border-radius as needed */
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    /* Adjust the box-shadow as needed */
    padding: 1rem;
    /* Adjust the padding as needed */
  }


}

/* If I click else where on the site I want the serach result to hide */

.srch::-webkit-scrollbar {
  width: 5px;
  /* Adjust the width of the scrollbar */
}

/* Track */
.srch::-webkit-scrollbar-track {
  background: transparent;
  /* Set the background color of the scrollbar track */
}

/* Handle */
.srch::-webkit-scrollbar-thumb {
  background: #008080;
  /* Set the color of the scrollbar handle */
  border-radius: 50px;
}

/* Handle on hover */
.srch::-webkit-scrollbar-thumb:hover {
  background: #006666;
  /* Set the color of the scrollbar handle on hover */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Apply the animation to an element with the "spinner" class */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid teal;
  /* You can adjust the color */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  /* Apply the spinning animation */
}