.search-bar-container {
    position: relative;
  }
  
  .search-input {
    width: 300px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
  }
  
  .dropdown-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .recent-searches {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    padding: 8px;
  }
  
  .recent-search-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .recent-search-item:hover {
    background-color: #f0f0f0;
  }
  
  .chip-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
  }
  
  .chip {
    background-color: #e0e0e0;
    color: #333;
    border-radius: 16px;
    padding: 4px 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-name {
    vertical-align: middle;
  }
  
  .chip-close-icon {
    margin-left: 8px;
    cursor: pointer;
  }
  